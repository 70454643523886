import commonHelper from '@/app/utility/common.helper.utility';
import assetViewDetails from '../assetAudit/assetViewDetails';


export default {
  name: 'myAssets',
  components: {assetViewDetails
  },
  watch: {
    currentPage: function() {
      this.getUserAssets()
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUserAssets()
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      showValueSetModal: false,
      myAssetsData:[],
      myAssetsFields:[
        {
          key:'item_name',
          label:'Asset Item'
        },
        {
            key:'doc_num',
            label:'Asset Number'
        },
        {
            key:'serial_no',
            label:'Serial Number'
        },
        {
            key:'fa_item_type',
            label:'Item Type'
        },
        {
          key:'ticket_number'
        },
        {
          key:'status_date'
        },
        {
            key:'location_name',
        },
        {
            key:'asset_details'
        }
      ],
      showAssetDetailsModalFlag:false,
      serialNum:null,
      assetNum:null,
    };
  },
  mounted() {
    this.getUserAssets()
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add' && !this.showOpenModal) {
        //   this.showHideAddFaLocationModal(true);
        } else if (actionName === 'update' || actionName === 'edit') {
          this.editMode = true;
        } else if (
          actionName === 'save'
        ) {
        //   this.addEditLocation();
        }
      }
    });
  },
  methods: {
    getUserAssets() {
      const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          serialNum:this.serialNum,
          docNum:this.assetNum
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getUserAssets', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
              this.myAssetsData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearUser() {
      this.serialNum=null;
      this.assetNum=null
    },
    showAssetDetailsModal(flag,asstDetail){
      this.assetNum=asstDetail;
      this.showAssetDetailsModalFlag=flag;
  },
  
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
